const Fourth = () => {
  return (
    <div id="4thpart">
      <div className="fourthbox" />
      <img className="cord-2-icon" alt="" src="/images/cord2@2x.png" />
      <div className="fourthbox-content">{`A team of experienced polymer scientists and engineers aided by innovative equipment designers envisioned  adapting highly oriented polymers for commercial and residential uses. Ballisticord ™ is the result of their efforts. `}</div>
    </div>
  );
};

export default Fourth;

const Footer = () => {
  return (
    <div className="footer">
      <div className="for-more-information">
        For more information, please contact us.
      </div>
      <a className="email" href="mailto:info@ballisticord.com">
        <div className="email-box" />
        <div className="email-text">info@ballisticord.com</div>
        <img className="icon-email" alt="" src="/images/iconemail.svg" />
      </a>
      <div className="phone">
        <div className="phone-box" />
        <div className="phone-text">253.606.0029</div>
        <img className="icon-phone" alt="" src="/images/iconphone.svg" />
      </div>
      <div className="ballisticord2">{`© 2023 Ballisticord. All rights reserved.  `}</div>
    </div>
  );
};

export default Footer;

const Fifth = () => {
  return (
    <div id="5thpart">
      <img
        className="adobestock-265758062-icon"
        alt=""
        src="/images/Fifth.png"
      />

      <div className="ballisticord-trimme1">
        <span className="ballisticord">Ballisticord</span>
        <sup>
          <span className="span"> ™ </span>
        </sup>
        <span className="ballisticord">
          {" "}
          Trimmer Line has been performance tested by or in the following
          environments over the past three years:
        </span>
      </div>

      <div className="group">
        <div className="smallbox" />
        <b className="golf-courses">Golf courses</b>
      </div>
      <img className="icon-golf" alt="" src="/images/icongolf.svg" />

      <div className="group1">
        <div className="smallbox" />
        <b className="municipal">Municipal and recreational parks</b>
        <img className="icon-park" alt="" src="/images/iconpark.svg" />
      </div>
      <div className="group2">
        <div className="smallbox" />
        <b className="commercial">Commercial contractors</b>
        <img
          className="icon-contractor"
          alt=""
          src="/images/iconcontractor.svg"
        />
      </div>
      <div className="group3">
        <div className="smallbox" />
        <b className="commercial">Residential and homeowner gardening uses</b>
        <img className="icon-house" alt="" src="/images/iconhouse.svg" />
      </div>
    </div>
  );
};

export default Fifth;

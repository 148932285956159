import "./Style.css";
import Header from "./Header";
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Fourth";
import Fifth from "./Fifth";
import Sixth from "./Sixth";
import Seventh from "./Seventh";
import Footer from "./Footer";

const App = () => {
  return (
    <div className="ballisticord-website">
      <Header />
      <Second />
      <Third />
      <Fourth />
      <Fifth />
      <Sixth />
      <Seventh />
      <Footer />
    </div>
  );
};

export default App;

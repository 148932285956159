const Sixth = () => {
  return (
    <div id="6thpart">
      <div className="oneof4">
        <img className="object-icon" alt="" src="/images/six1.png" />
        <div className="object" />
        <b className="oneof4text">
          Exceptional wear resistance: lasts 6 times longer than nylon
        </b>
        <b className="x">6x</b>
      </div>
      <div className="twoof4">
        <img className="object-icon" alt="" src="/images/six2.png" />
        <b className="oneof4text">Reduced vibration</b>
      </div>
      <div className="circle2nd" />
      <img className="icon-vibrate" alt="" src="/images/iconvibrate.svg" />

      <div className="threeof4">
        <img className="object-icon" alt="" src="/images/six3.png" />

        <div className="object" />
        <b className="oneof4text">Quieter operation compared to nylon</b>
        <div className="circle3rd" />
        <img className="icon-noise" alt="" src="/images/iconnoise.svg" />
      </div>

      <div className="fourof4">
        <img className="object-icon" alt="" src="/images/six4.png" />

        <div className="object" />
        <b className="oneof4text">
          Reduced down-time for rewinding broken trimmer line
        </b>
      </div>
      <div className="circle4th" />
      <img className="icon-timing" alt="" src="/images/icontiming.svg" />
    </div>
  );
};

export default Sixth;

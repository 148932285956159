const Third = () => {
  return (
    <div id="3rdpart">
      <div className="oriented-polymers-ar">
        Oriented polymers are extensively used in personnel and vehicular
        ballistic armour applications. Our proprietary material results in
        longer product life expectancy and durability. Its lightness (10%
        lighter than nylon) offers opportunities for significant increases in
        lithium-ion battery run time.
      </div>
      <img
        className="grass-clippings-icon"
        alt=""
        src="/images/grassclippings@2x.png"
      />
    </div>
  );
};

export default Third;

const Header = () => {
  return (
    <div id="1stpart">
      <img className="ballisticord-logo" alt="" src="/images/logo.svg" />
      <div className="box-contactus" />
      <a className="contact-us" href="mailto:info@ballisticord.com">
        Contact Us
      </a>
      <div className="firstbox">
        <img className="image1" alt="" src="/images/Header.png" />
      </div>
      <h3 className="first-heading">The new revolution in trimming devices.</h3>

      <div className="secondbox" />
      <img className="cord-copy-icon" alt="" src="/images/cord-copy@2x.png" />
      <h5 className="secondbox-content">
        <b>
          <span>
            Ballisticord <sup>™</sup> Trimmer Line
          </span>
        </b>
        <span className="secondbox-content-text">
          <br />
          <span className="secondbox-content-text-below">{`products are the result of new and advanced polymer processing technology developed specifically for line trimming devices. `}</span>
        </span>
      </h5>
    </div>
  );
};
export default Header;

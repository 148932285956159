const Seventh = () => {
  return (
    <div id="7thpart">
      <div className="rectangle-main" />
      <div className="thickness-bar" />
      <div className="thickness-bar-progress" />
      <div className="width-bar" />
      <div className="width-bar-progress" />
      <div className="ballisticord-trimme">
        <span className="ballisticord-trimme-txt-container">
          <span className="ballisticord">Ballisticord</span>
          <sup>
            <span className="span"> ™ </span>
          </sup>
          <span className="ballisticord"> Trimmer Line is available in:</span>
        </span>
      </div>
      <b className="profiles">Profiles</b>
      <b className="thickness">Thickness</b>
      <div className="centertext">{`Square `}</div>
      <div className="thickness-botton-txt">.060” to .125”</div>
      <b className="width">Width</b>
      <div className="width-botton-txt">.060” up to .375”</div>
    </div>
  );
};

export default Seventh;

const Second = () => {
  return (
    <div id="2ndpart">
      <div className="thirdbox" />
      <div className="thirdbox-content">
        <span className="span-text">{`Unlike existing nylon string alternatives, `}</span>
        <b className="bold-text">
          Ballisticord<sup>™</sup> Trimmer Line
        </b>
        <span className="span-text">{` is our proprietary material known for exceptional wear resistance and impact absorption properties.  `}</span>
      </div>
      <b className="longer-life-expectan">{`Longer Life Expectancy `}</b>
      <img className="oval-icon" alt="" src="/images/oval.svg" />
      <img className="oval-icon1" alt="" src="/images/oval.svg" />
      <img className="oval-icon2" alt="" src="/images/oval.svg" />
      <img className="icon-calendar" alt="" src="/images/iconcalendar.svg" />
      <b className="increased-durability">Increased Durability</b>
      <img className="icon-chart" alt="" src="/images/iconchart.svg" />
      <b className="lighter-than-nyl-container">
        <span className="ballisticord-trimme-txt-container">
          <p className="lighter">10% Lighter</p>
          <p className="lighter">Than Nylon</p>
        </span>
      </b>
      <img className="icon-weight-v2" alt="" src="/images/iconweightv2.svg" />
    </div>
  );
};

export default Second;
